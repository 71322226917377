"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
const LinkWrapper = _styledComponents.default.a.withConfig({
  displayName: "LinkWrapper",
  componentId: "d57ew4-0"
})(["text-decoration:none !important;color:inherit !important;"]);
var _default = exports.default = LinkWrapper;