"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _getUnitTypeConsts = _interopRequireDefault(require("../constants/getUnitTypeConsts"));
var _messages = _interopRequireDefault(require("../constants/messages"));
var _capitalize = _interopRequireDefault(require("lodash/capitalize"));
var _capitalizeEveryWord = _interopRequireDefault(require("./capitalizeEveryWord"));
// by default, we just show the brand names  capitalized. but exceptions can be added to this map.
const brandLabelMap = {
  schier: 'Schiermonnikoog'
};
const getRentalUnitSummaryText = (intl, brandConfig) => {
  const unitTypeConsts = (0, _getUnitTypeConsts.default)(intl);
  return (place, type, amountPersons, amountBedrooms, brand) => {
    let placeString = '';
    if (!brandConfig.hidePlaceInSearchTile) {
      placeString = `${place}, `;
      if (brand && brandConfig.showBrandInSearchTile) {
        const brandLabel = brandLabelMap[brand] ?? (0, _capitalize.default)(brand);

        // show brand instead of place
        placeString = `${brandLabel}, `;
      }
    }
    const placeType = type ? `${unitTypeConsts[type].translation}, ` : '';
    const placeTypeString = `${(0, _capitalizeEveryWord.default)(placeString)} ${placeType}`;
    const personsString = intl.formatMessage(_messages.default.persons, {
      amountPersons
    });
    if (!amountBedrooms) {
      // "x persons"
      return placeTypeString + personsString;
    }
    // "x persons , y bedrooms"
    return `${placeTypeString}${personsString}, ${intl.formatMessage(_messages.default.bedrooms, {
      amountBedrooms
    })}`;
  };
};
var _default = exports.default = getRentalUnitSummaryText;